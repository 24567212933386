// @ts-nocheck
import {
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Layout,
  Card,
  TextContainer,
} from "@shopify/polaris";

const loadingPageMarkup = () => {
  return (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
};

export default loadingPageMarkup;
