import {
  Frame,
  Icon,
  TextStyle,
  Image,
  ContextualSaveBar,
  VisuallyHidden,
  Page,
  Navigation,
  TopBar,
  Card,
  ProgressBar,
  Banner,
  Link,
  ActionList,
  Layout,
  FormLayout,
  TextField,
  SkeletonPage,
  TextContainer,
  SkeletonDisplayText,
  SkeletonBodyText,
  Spinner,
  Badge,
  Popover,
} from "@shopify/polaris";
import {
  HeartMajor,
  BillingStatementRupeeMajor,
  BillingStatementDollarMajor,
  OrdersMajor,
  ProductsMajor,
  OnlineStoreMajor,
  PlusMinor,
  ArrowLeftMinor,
  ConversationMinor,
  CustomersMajor,
  SocialPostMajor,
  AnalyticsMajor,
  ReportsMajor,
  NoteMajor,
  QuestionMarkMajor,
  SendMajor,
  SettingsMajor,
  DnsSettingsMajor,
  AttachmentMajor,
  ChatMajor,
  IllustrationMajor,
  ListMajor,
  PaintBrushMajor,
  AppsMajor,
} from "@shopify/polaris-icons";
import React, { useRef, useState, useCallback, useEffect } from "react";
import {
  myRequest,
  getReviewRequests,
  getReviews,
  reviewsApproval,
  getReviewStatusCount,
} from "../api";
import { Router, useRouter } from "next/router";
import useSWR, { mutate } from "swr";
import Head from "next/head";
import Cookies from "universal-cookie";
import mixpanel from "mixpanel-browser";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { FiTrendingUp } from "react-icons/fi";
import Loading from "../components/Loading";
import Homeupgrade from "../components/Dashboard/Homeupgrade";
import Styles from "@/components/Wrapper.module.scss";
import { MODE } from "utils/Base";
import Loader from "./Loader";
// import Loadingspinner from "./Loadingspinner";

function Wrapper({
  children,
  progress,
  authenticated,
  isPremium,
  spinnerBar,
  approvedCount,
  currentChatFlow = "",
  draftStatus = "synced",
  navigation = true,
}) {
  const cookies = new Cookies();
  useEffect(() => {
    if (!isPremium && approvedCount + authenticated >= 50) {
      if (!cookies.get("upgradepopup")) {
        setTimeout(() => {
          // setlimitModal(true);
        }, 2 * 60 * 1000);

        cookies.set("upgradepopup", "upgradepopup", {
          path: "/",
          expires: new Date(Date.now() + 5 * 24 * 60 * 60 * 10000),
        });
        cookies.set("showoneday", "showoneday", {
          path: "/",
          expires: new Date(Date.now() + 24 * 60 * 60 * 10000),
        });
      } else if (cookies.get("upgradepopup")) {
        setlimitModal(false);
        if (cookies.get("showoneday")) {
          // setTimeout(() => {
          //     setlimitModal(true);
          //   }, 2 * 60 * 1000);
          // setlimitModal(true);
        }
      }
    }
  }, [isPremium]);


  useEffect(()=>{
    window.userlist=window.userlist||function(){(userlist.q=userlist.q||[]).push(arguments)};
  },[])

  const router = useRouter();
  const defaultState = useRef({
    emailFieldValue: "ecartchat.myshopify.com",
    nameFieldValue: "Admin",
  });
  const [limitModal, setlimitModal] = useState(false);
  const didMountRef = useRef(false);
  const [seconds, setSeconds] = useState(0);
  const skipToContentRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setpageLoading] = useState(false);
  const [notification, setNotification] = useState(0);
  const [userMenuActive, setUserMenuActive] = useState(false);

  const [isSecondaryMenuOpen, setIsSecondaryMenuOpen] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [nameFieldValue, setNameFieldValue] = useState(
    defaultState.current.nameFieldValue
  );
  const [emailFieldValue, setEmailFieldValue] = useState(
    defaultState.current.emailFieldValue
  );
  const [storeName, setStoreName] = useState(
    defaultState.current.nameFieldValue
  );
  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    []
  );
  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );
  const toggleIsSecondaryMenuOpen = useCallback(
    // () => setIsSecondaryMenuOpen((isSecondaryMenuOpen) => !isSecondaryMenuOpen),
    () => {
      setpageLoading(true);
      router.push("/pricing");
    },
    []
  );
  const toggleIsLoading = useCallback(() => setIsLoading(!isLoading), []);
  const handleLogout = () => {
    // console.log("logout")
    cookies.remove("merchantToken");
    cookies.remove("shopName");
    cookies.remove("shopId");
    cookies.remove("shopDomain");
    cookies.remove("onBoardingFlow");
    localStorage.clear();
    router.push("/Login");
  };

  const userMenuActions = [
    {
      items: [{ content: "Logout", onAction: handleLogout }],
    },
  ];
  // const cookies = new Cookies();
  // const{ data ,error, mutate} = authenticated?useSWR([authenticated],getReviewStatusCount):cookies.get('merchantToken')
  let statusCount = authenticated;
  let load = false;
  // if (error){
  // console.log("Errrrrrr", error)
  // router.push("/Login")}
  // else
  // if (data) {
  //     //
  //     statusCount =data?.data?.count
  //     load=false
  //     // router.push("/404")
  //     // load=false
  // }
  // else{

  //     load=true
  // }

  // MIXPANNEL INIT
  mixpanel.init(
    "ba93717cfcf67a314de49229fdfb92c0",
    { api_host: "https://api-eu.mixpannel.com" },
    ""
  );

 
  const shopname = cookies.get("shopName");

  const userMenuMarkup = (
    <TopBar.UserMenu
      // sectionMarkup
      actions={userMenuActions}
      name={shopname}
      detail={storeName}
      initials={shopname && shopname.slice(0, 1).toUpperCase()}
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
    // <Spinner size="small"/>
  );
  const userMenuMarkups = (
    <TopBar.UserMenu
      // sectionMarkup
      actions={userMenuActions}
      name={shopname}
      detail={storeName}
      initials="E"
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
    // <Spinner size="small"/>
  );

  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );

  const secondaryMenuMarkup = (
    <>
      {isPremium ? (
        <></>
      ) : (
        <TopBar.Menu
          activatorContent={
            <span
              style={{
                width: "100px",
                background: "#fcf5ed",
                padding: "3px",
                borderRadius: "3px",
                color: "black",
              }}
            >
              <Image
                source="./images/crown.png"
                width="20px"
                style={{ marginRight: "10px" }}
              />
              <TextStyle>Upgrade</TextStyle>
            </span>
            // {sectionMarkup}
          }
          open={isSecondaryMenuOpen}
          onOpen={toggleIsSecondaryMenuOpen}
          actions={[]}
        />
      )}
    </>
  );
  // const topBarPrice= (
  //     <TopBar
  //        name={"dwbqjbdjihi"}
  //     />
  // );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      // sectionMarkup
      userMenu={userMenuMarkup}
      onNavigationToggle={toggleMobileNavigationActive}
      secondaryMenu={secondaryMenuMarkup}
      // sectionMarkup={userMenuMarkups}
    />
  );
  // const spinnerTop =(
  //     <Spinner size="small"/>
  // )
  // const loadingMarkup = isLoading ?  <Spinner size="small"/>: null;

  const navigationMarkup = (
    <Navigation location={router.pathname}>
      <div className={Styles.logo}>
        <img
          onClick={() => router.push("/")}
          src="/logo.png"
          alt="Reviewbit"
          title="Reviewbit"
        />
        {MODE == "DEVELOPMENT" && (
          <div className={Styles.notIcon}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5002 14.1667H16.6668L15.496 12.9958C15.3388 12.8386 15.214 12.6519 15.129 12.4464C15.0439 12.2409 15.0001 12.0207 15.0002 11.7983V9.16667C15.0003 8.13245 14.6798 7.12362 14.0829 6.27907C13.4859 5.43453 12.6418 4.7958 11.6668 4.45083V4.16667C11.6668 3.72464 11.4912 3.30072 11.1787 2.98816C10.8661 2.67559 10.4422 2.5 10.0002 2.5C9.55813 2.5 9.13421 2.67559 8.82165 2.98816C8.50909 3.30072 8.3335 3.72464 8.3335 4.16667V4.45083C6.39183 5.1375 5.00016 6.99 5.00016 9.16667V11.7992C5.00016 12.2475 4.82183 12.6783 4.50433 12.9958L3.3335 14.1667H7.50016M12.5002 14.1667H7.50016M12.5002 14.1667V15C12.5002 15.663 12.2368 16.2989 11.7679 16.7678C11.2991 17.2366 10.6632 17.5 10.0002 17.5C9.33712 17.5 8.70124 17.2366 8.2324 16.7678C7.76355 16.2989 7.50016 15.663 7.50016 15V14.1667"
                stroke="#F6F6F7"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <small>6</small>
          </div>
        )}
      </div>
      <Navigation.Item
        label="Dashboard"
        icon={AnalyticsMajor}
        url="/"
        exactMatch={true}
        onClick={() => {
          if (router.pathname != "/") {
            toggleIsLoading();
          }
        }}
      />
      {currentChatFlow == "oldChatflow" ? (
        <Navigation.Item
          label="Chat Flow Settings"
          icon={SettingsMajor}
          url="/whatsapp"
          onClick={() => {
            if (router.pathname != "/whatsapp") {
              toggleIsLoading();
            }
          }}
        />
      ) : (
        <Navigation.Item
          label="Chat Flow Settings"
          icon={SettingsMajor}
          url="/settings"
          onClick={() => {
            if (router.pathname != "/settings") {
              toggleIsLoading();
            }
          }}
        />
      )}

      <style>
        {`
        .Polaris-Navigation__Text{
         white-space: nowrap;
        }
        
        .Polaris-Navigation__Item{
          align-items: center
        }
        `}
      </style>
      <Navigation.Item
        style={{ alignItems: "center" }}
        label="Widget"
        icon={IllustrationMajor}
        url="/review-widget"
        onClick={() => {
          if (router.pathname != "/review-widget") {
            toggleIsLoading();
          }
        }}
        badge={
          <div
            style={{
              backgroundColor: "rgba(255, 234, 138)",
              padding: "5px",
              borderRadius: "10px",
              height: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "5px",
            }}
          >
            <i style={{ fontWeight: "400", color: "#000", fontSize: "12px" }}>
              New
            </i>
          </div>
        }
      />
      <Navigation.Item
        label="Branding"
        icon={PaintBrushMajor}
        url="/branding"
        onClick={() => {
          if (router.pathname != "/branding") {
            toggleIsLoading();
          }
        }}
      />
      <Navigation.Item
        label="Reviews"
        icon={SocialPostMajor}
        url="/reviews"
        onClick={() => {
          if (router.pathname != "/reviews") {
            toggleIsLoading();
          }
        }}
        badge={<span>{statusCount}</span>}
      />
      {MODE == "TEST" && (
        <Navigation.Item
          label="Products"
          icon={ProductsMajor}
          url="/product-review"
          onClick={() => {
            if (router.pathname != "/product-review") {
              toggleIsLoading();
            }
          }}
        />
      )}

      <Navigation.Item
        label="Review Requests"
        icon={SendMajor}
        url="/review-request"
        onClick={() => {
          if (router.pathname != "/review-request") {
            toggleIsLoading();
          }
        }}
      />
      <Navigation.Item
        label="Integrations"
        icon={AttachmentMajor}
        url="/integration"
        onClick={() => {
          if (router.pathname != "/integration") {
            toggleIsLoading();
          }
        }}
      />

      {/* <Navigation.Item
                label="Billing"
                icon={BillingStatementRupeeMajor}
                url='/payment'
                onClick={toggleIsLoading}
            /> */}
      {/* temp hide */}
      {/* <Navigation.Item
                label="Integrations"
                icon={DnsSettingsMajor}
                url='/integrations'
                onClick={toggleIsLoading}
            /> */}
      <div className="navbar-bottom-section">
        {/* <Navigation.Item
                    label={<img src="/images/lang-widget.png" alt="" width="100%" />}
                /> */}
        <div id="google_translate_element"></div>
        <Navigation.Section
          title="HELP & SUPPORT"
          separator
          items={[

            {
              //url: 'https://www.reviewbit.app/help/?utm_source=dashboard',
              label: "Documentation",
              icon: NoteMajor,
              onClick: () => {
                window.open(
                  "https://www.reviewbit.app/help/?utm_source=dashboard",
                  "_blank"
                );
              },
            },
            {
              url: "mailto:info@reviewbit.app",
              label: "Support",
              icon: QuestionMarkMajor,
            },
            {
              url: "/pricing",
              label: "Plans",
              icon: ListMajor,
              onClick: () => {
                if (router.pathname != "/pricing") {
                  toggleIsLoading();
                }
              },
            },
            {
              url: "/billing",
              label: "Billing",
              icon: BillingStatementDollarMajor,
              onClick: () => {
                if (router.pathname != "/billing") {
                  toggleIsLoading();
                }
              },
            },
            {
              url: "/app-recommendation",
              label: "Apps Recommendation",
              icon: AppsMajor,
              onClick: () => {
                if (router.pathname != "/app-recommendation") {
                  toggleIsLoading();
                }
              },
            }
          ]}
        />
        <Navigation.Section
          separator
          items={[
            {
              url: "https://apps.shopify.com/give-me-a-feedback#modal-show=ReviewListingModal",
              label: "Write a review",
              icon: HeartMajor,
            },
          ]}
        />{" "}
        <Navigation.Section separator items={[]} />
        <Popover
          active={popoverActive}
          activator={
            <div className={Styles.userBox} onClick={togglePopoverActive}>
              <div className={Styles.img}>
                {shopname && shopname.slice(0, 1).toUpperCase()}
              </div>
              <div className={Styles.right}>
                <h6>{storeName}</h6>
                <h5>{shopname}</h5>
              </div>
            </div>
          }
          autofocusTarget="first-node"
          onClose={togglePopoverActive}
        >
          <ActionList
            actionRole="menuitem"
            items={[{ content: "Logout", onAction: handleLogout }]}
          />
        </Popover>
      </div>
      {/* <Navigation.Item
                label="Write a review"
                icon={HeartMajor}
                url='/write_review'
                onClick={toggleIsLoading}
            /> */}
    </Navigation>
  );
  //     const sectionMarkup =
  //     isLoading ? (
  //         <Spinner
  //         size="small"
  //         //   accessibilityLabel="Loading form field"
  //           hasFocusableParent={false}
  //           spinnerBar={spinnerBar | load == true ? 80 : 0}

  //         />
  //    ) :""

  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
  useEffect(() => {
    // const interval = setInterval(() => {
    //     setSeconds(seconds => seconds + 1);
    //     console.log(seconds)
    //     if(seconds==10){
    //         googleTranslateElementInit()
    //     }
    //   }, 1000);
    //   return () => clearInterval(interval);
  }),
    [seconds];

  if (seconds < 2) {
    setTimeout(() => {
      setSeconds(seconds + 1);
      //   console.log(seconds)
      if (seconds == 1) {
        googleTranslateElementInit();
      }
    }, 2700);
  }
  function googleTranslateElementInit() {
    console.log("SDSDSDSDSD");
    new google.translate.TranslateElement(
      { pageLanguage: "en" },
      "google_translate_element"
    );
  }

  return (
    <React.Fragment>
      {/* <div className={Styles.announcementbanner}>
        <a
          href="https://docs.google.com/spreadsheets/d/1N9EySOSs3nMi_Lem1b6uC2rXHgz9lcctv3SRBVsWa0w/edit#gid=0"
          target="_blank"
        >
          Click here
        </a>
        <p>
          to view the new whatsapp message price effective from
          <span>Nov 10th 2021.</span>
        </p>
      </div> */}

      <Head>
        <title>Reviewbit Dashboard</title>
        <link
          rel="shortcut icon"
          type="image/jpg"
          href="https://storage.googleapis.com/reviewbit/fav-ico.png"
        />
        <script type="text/javascript" src="js/custom.js"></script>
        {/* Google Analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-170157752-1"
        />
        <script>{`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'UA-170157752-1');
                `}</script>

        {/* Clarity */}
        <script>{`
            (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "675xklehvf");
          `}</script>
          
           <script>{`
      
      (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:3167404,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
  </script>

        {/* mixin */}
        {/* <script>{`(function(f,b){if(!b.__SV){var e,g,i,h;window.mixpanel=b;b._i=[];b.init=function(e,f,c){function g(a,d){var b=d.split(".");2==b.length&&(a=a[b[0]],d=b[1]);a[d]=function(){a.push([d].concat(Array.prototype.slice.call(arguments,0)))}}var a=b;"undefined"!==typeof c?a=b[c]=[]:c="mixpanel";a.people=a.people||[];a.toString=function(a){var d="mixpanel";"mixpanel"!==c&&(d+="."+c);a||(d+=" (stub)");return d};a.people.toString=function(){return a.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
            for(h=0;h<i.length;h++)g(a,i[h]);var j="set set_once union unset remove delete".split(" ");a.get_group=function(){function b(c){d[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));a.push([e,call2])}}for(var d={},e=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<j.length;c++)b(j[c]);return d};b._i.push([e,f,c])};b.__SV=1.2;e=f.createElement("script");e.type="text/javascript";e.async=!0;e.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
            MIXPANEL_CUSTOM_LIB_URL:"file:"===f.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\/\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";g=f.getElementsByTagName("script")[0];g.parentNode.insertBefore(e,g)}})(document,window.mixpanel||[]);
            mixpanel.init("ba93717cfcf67a314de49229fdfb92c0", {batch_requests: true})`}</script> */}
        <script
          async
          type="text/javascript"
          src="https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/390b55d6063b4ea2a2595e55bbac0637c7993d9bcabc4374bc635922fc737a9d.js"
        ></script>
        
      
{/* <script async data-userlist="insert-generated-user-token-here" src="https://js.userlist.com/v1"></script> */}
       
      </Head>
      {limitModal && (
        <Homeupgrade
          open={limitModal}
          togglePopup={setlimitModal}
          //   togglePopup={() => setlimitModal(!limitModal)}
          //   approvedCount={approvedCount}
          //   title={modalTitle}
        />
      )}

      <Frame
        // topBar={topBarPrice}
        // topBar={topBarMarkup}
        //  sectionMarkup={sectionMarkup}
        navigation={navigation ? navigationMarkup : null}
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
        skipToContentTarget={skipToContentRef.current}
      >
        {/* {loadingMarkup} */}
        <div className="stickyProgress">
          <ProgressBar
            progress={progress || load == true ? 80 : 0}
            size="small"
          />
          {/* <span className={Styles.spinners}>{sectionMarkup}</span> */}
          {/* {isLoading ? <Spinner size="small"/>  : ""}  */}
        </div>
        {navigation && (
          <div id="mobileMenu">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setMobileNavigationActive(!mobileNavigationActive)}
              width="28"
              height="28"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#000"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="3" y1="12" x2="21" y2="12"></line>
              <line x1="3" y1="6" x2="21" y2="6"></line>
              <line x1="3" y1="18" x2="21" y2="18"></line>
            </svg>
            <h5>{shopname}</h5>
          </div>
        )}
        {pageLoading || isLoading ? (
          <div className="fullPageLoader">
            <Loader />
          </div>
        ) : (
          children
        )}

        {/* <Loadingspinner/> */}
        {/* <div>
{sectionMarkup}
</div> */}
      </Frame>
    </React.Fragment>
  );
}

export default Wrapper;
