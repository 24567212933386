import {
  Modal,
  Layout,
  Button,
  Stack,
  Heading,
  ChoiceList,
  DisplayText,
  Image,
  Spinner,
  TextContainer,
  TextStyle,
  Banner,
} from "@shopify/polaris";
import { useState, useCallback } from "react";
import { Router, useRouter } from "next/router";
import Style from "./pastcustomer.module.scss";
import toastr from "react-hot-toast";
// import toastr from "../../lib/toastr";
import { upgradetoPremium, AuthVerify } from "../../api";
import Cookies from "universal-cookie";

const HomePageModal = ({
  //  togglePopup = () => { },
  togglePopup = {},
  open = false,
  approvedCount = 0,
  title = "Upgarde your account to unlock our premium features!",
}) => {
  const cookies = new Cookies();
  const router = useRouter();
  const [loading, setloading] = useState(false);

  const premiumFeatures = [
    { text: "Send automated request", type: "success" },
    { text: "Photo and video reviews", type: "success" },
    { text: "Publish unlimited Reviews", type: "success" },
    { text: "Unlimited review listing", type: "success" },
    { text: "Integrate with all platforms", type: "success" },
    { text: "Remove Reviewbit branding", type: "success" },
  ];
  const handleUpgrade = (val) => {
    setloading(true);
    upgradetoPremium(val).then(
      (response) => {
        // console.log(response);
        setloading(false);

        window.location.href = response.data.confirmation_url;
      },
      (error) => {
        toastr.error("Error! Please try again");
        setloading(false);
        // console.log(error);
      }
    );
  };

  const dotitlaterhandle = () => {
    cookies.remove("showoneday");
    togglePopup(false);
  };
  const onclosehandle = () => {
    cookies.remove("showoneday");
    togglePopup(false);
  };

  return (
    <Modal
      title=""
      titleHidden
      open={open}
      onClose={onclosehandle}
      // title="Export Reviews"
      // titleHidden
      // primaryAction={{
      //     content:  "Upgrade Now",
      //     onAction: handleExport,
      // }}
      // secondaryActions={[
      //     {
      //         content: 'Do it Later',
      //         onAction: togglePopup,
      //     },
      // ]}
    >
      <div style={{ padding: "2.75rem 2.5rem 0 2.5rem" }}>
        <DisplayText size="medium">{title}</DisplayText>
        <div style={{ marginTop: "3px" }}>
          <TextStyle>You can continue as free upto 50 approvels</TextStyle>
        </div>
      </div>
      <div style={{ display: "flex", padding: "1.5rem 3rem 2rem 3rem" }}>
        <div style={{ width: "50%" }}>
          <TextStyle variation="positive">Now get it for just</TextStyle>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginTop: "3px",
            }}
          >
            <TextStyle variation="subdued">
              <s>$29</s>
            </TextStyle>
            &nbsp;
            <DisplayText size="large">Growth Plan*</DisplayText>
            &nbsp;
            <TextStyle variation="subdued">/mon</TextStyle>
          </span>
          <br />
          <TextStyle variation="strong">You also get</TextStyle>
          <ul className={Style.features}>
            {premiumFeatures?.map((item) => (
              <li>
                {item?.type === "success" ? (
                  <Image
                    source="./images/green-check.png"
                    style={{ marginRight: "5px" }}
                  />
                ) : (
                  <Image
                    source="./images/yellow-caution.png"
                    style={{ marginRight: "5px" }}
                  />
                )}
                <span>{item.text}</span>
              </li>
            ))}
          </ul>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginBottom: "2rem",
              marginTop: "3rem",
            }}
          >
            <span>*</span>
            <Button
              size="slim"
              plain={true}
              external={true}
              removeUnderline={true}
              monochrome
              url="https://docs.google.com/spreadsheets/d/1N9EySOSs3nMi_Lem1b6uC2rXHgz9lcctv3SRBVsWa0w/edit#gid=0"
            >
              Whatsapp charges extra
            </Button>
            {/* <TextStyle >
                        *Whatsapp charges extra
                    </TextStyle> */}
            <Image source="./images/info.png" style={{ marginLeft: "5px" }} />
          </span>
        </div>
        <div style={{ width: "50%" }}>
          <Image
            source="./images/upgrade-illustration.png"
            style={{
              marginLeft: "5px",
              paddingLeft: "4rem",
              marginTop: "6rem",
            }}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginTop: "6rem",
            }}
          >
            <Button
              size="slim"
              plain={true}
              monochrome
              removeUnderline={true}
              onClick={dotitlaterhandle}
            >
              Do it Later
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              primary
              onClick={() => {
                // setloading(true);
                router.push("/pricing");
              }}
            >
              {loading ? "Loading..." : "Upgrade Now"}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default HomePageModal;
